//import logo from './logo.svg';
//import background from "./components/cxc2.jpg";
//
import './App.css';
//import React, { Component } from 'react';
//https://www.geeksforgeeks.org/link-and-navlink-components-in-react-router-dom/
//import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom' //issue with link??
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Products from './pages/products';
import NoPage from './pages/nopage';
import STTGal from './pages/sttgal';
// good navigation https://www.codingdeft.com/posts/react-router-tutorial/
//<Route path = "/contact/:email" element= {<Contact />}/> //passing an email parameter
//import Navbar from './components/navbar';
//import "./styles.css"
//https://codesandbox.io/s/styling-react-router-linksstarter-template-tctnj?file=/src/App.js:193-215
//import styled from "styled-components"; //not sure why we are not using
import { NavLink } from "react-router-dom";
//import STTGal from './pages/sttgal';
//import Nav from './components/nav'
/*
const NavUnlisted = styled.ul`
  display: flex;
  a {
    text-decoration: none;
  }
  li {
    color: red;
    margin: 0 0.8rem;
    font-size: 1.3rem;
    position: relative;
    list-style: none;
  }

  .current {
    li {
      border-bottom: 2px solid black;
    }
  }
`;
*/

function App() {
  return (
    <div style = {{backgroundImage: "url(./cxc2.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat:"no-repeat",
    height: "100vh"
  }}>

    <Router>


                <div style={{
                    display: "flex",
                    background: 'black',
                    padding: '5px 0 5px 5px',
                    fontSize: '20px'
                }}>
                    <div style={{ margin: '10px' }}>
                        <NavLink to="/" style={({ isActive }) => ({
                            color: isActive ? 'aqua' : 'white' })}>
                            Home
                        </NavLink>
                    </div>
                    <div style={{ margin: '10px' }}>
                        <NavLink to="/products" style={({ isActive }) => ({
                            color: isActive ? 'aqua' : 'white' })}>
                            Products
                        </NavLink>
                    </div>
                    <div style={{ margin: '10px' }}>
                        <NavLink to="/about" style={({ isActive }) => ({
                            color: isActive ? 'aqua' : 'white' })}>
                            About
                        </NavLink>
                    </div>
                    <div style={{ margin: '10px' }}>
                        <NavLink to="/contact" style={({ isActive }) => ({
                            color: isActive ? 'aqua' : 'white' })}>
                            Contact
                        </NavLink>
                    </div>
                </div>


      <Routes>
        <Route path = "/" element= {<Home />}/>
        <Route path = "/products" element= {<Products />}/>
        <Route path = "/about" element= {<About />}/>
        <Route path = "/contact" element= {<Contact />}/>
        <Route path = "/sttgal" element= {<STTGal />}/>
        <Route path = "*" element= {<NoPage />}/>
      </Routes>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
<center><footer style={{ color: 'white' }}>&copy;2023 CXC Dev Team</footer></center>

    </Router>
    </div>
  );
}

export default App;
