import React from "react";
import { useNavigate } from "react-router-dom";

function About() {
    const navigate = useNavigate()
    return (
        <div>
        <h1 style={{ color: 'White'}}  >
        About Page
        </h1>
        <button onClick={()=>{navigate("/")}}>Go Home</button>
        <button onClick={()=>{navigate("/contact")}}>Contact Us</button>
        </div>
    )
}
export default About;