
//import React from "react";
import React, { useState } from "react";

const images = [
  '/STT/STT1.JPG',
  '/STT/STT2.JPG',
  '/STT/STT3.JPG',
  '/STT/STT4.JPG',
  '/STT/STT5.JPG',
  '/STT/STT6.JPG',
  '/STT/STT7.JPG',
  '/STT/STT8.JPG',
  '/STT/STT9.JPG'
];

function STTGal() {
    console.log('Made it to the STTGal function');
  const [index, setIndex] = useState(0);

  const handleNext = () => {
    setIndex((index + 1) % images.length);
  };

  const handlePrev = () => {
    setIndex((index - 1 + images.length) % images.length);
  };

  return (
    
    <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
      }}
    >
        
      <button onClick={handlePrev}>Prev</button>
      <img src={images[index]} alt="Simple Tube Tracker" />
      <button onClick={handleNext}>Next</button>
    </div>
  );
}

export default STTGal;