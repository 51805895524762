import React from "react";
import { Link } from 'react-router-dom';
console.log('in the products page');
function Products() {
  console.log('in the products page2');
  return (
    
    <div>
      <h1 style={{ color: 'aqua' }}>
        Products Page
      </h1>
      <span style={{ color: 'White' }}>
        <h3>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Current Products: <br/>
      
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1) <Link to="/sttgal">Simple Tube Tracker</Link><br/>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2) Commerceconx Flagship product (in Beta at this time)
        </h3>
        </span>
    </div>
  );
};
  
export default Products;