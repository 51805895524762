//import React from "react";
//import { useParams } from "react-router-dom";   //passing an email parameter
import React, { useState } from "react";
//import emailjs from 'emailjs-com'; //new


function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // Do something with the form data, such as send it to a server.
    console.log("Name:", name);
    console.log("Email:", email);
    console.log("Message:", message);
    // Clear the form
    setName("");
    setEmail("");
    setMessage("");
  };
  
  return (
    <center><div>
      <h1 style={{ color: 'White'}}  >Contact Us</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div >
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          ></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
    </center>
  );
}


  
export default Contact;