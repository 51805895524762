import React from "react";


function Home() {
    return (
        <div style={{ color: 'White'}} 
        >
        <h1 >
        Commerce Conx
        </h1>
         <br/>
         <h3 style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
      }}>
        Connect your back-end Business Solutions. <br/>
        Expertly-designed software, easy-to-use interface.
        </h3>
        </div>
        
    )
}
export default Home;